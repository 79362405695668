body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4 {
  font-family: "Trebuchet MS";
  color: #333333;
}

.btn-default {
  color: #006b92;
}

.logo {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
}

.carousel .slide {
  background: none !important;
}
