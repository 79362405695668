p {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.company-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}

.navBar-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
}
